@font-face
	font-family: "svgfont"
	src: url('./../fonts/svgfont.woff2') format('woff2'), url('./../fonts/svgfont.woff') format('woff'), url('./../fonts/svgfont.eot'), url('./../fonts/svgfont.eot?#iefix') format('embedded-opentype'), url('./../fonts/svgfont.ttf') format('truetype')
	font-weight: normal
	font-style: normal

$icon-sw-on: "\EA01"
$icon-out: "\DB68"
$icon-people: "\EA02"
$icon-favourite: "\EA04"
$icon-Facebook: "\EA05"
$icon-linkedin: "\EA06"
$icon-Instagram: "\EA07"
$icon-stars: "\EA08"
$icon-send: "\EA09"
$icon-arrow-long: "\EA10"
$icon-close-pass: "\EA11"
$icon-open-pass: "\EA12"
$icon-arrow-small: "\EA14"
$icon-fb: "\EA15"
$icon-share: "\EA17"
$icon-inst: "\EA18"
$icon-tw: "\EA19"
$icon-download: "\EA20"
$icon-test-svg: "\EA21"
$icon-heart: "\EA22"
$icon-sw-off: "\EA23"
$icon-like-active: "\EA24"
$icon-catalog: "\EA25"
$icon-employees: "\EA26"
$icon-featured: "\EA27"
$icon-my-orders: "\EA28"
$icon-profile-data: "\EA29"
$icon-subscribe: "\EA30"
$icon-krestik: "\EA31"
$icon-reset: "\EA32"
$icon-search: "\EA33"
$icon-troetochie: "\EA34"
$icon-open: "\EA35"
$icon-close: "\EA36"
$icon-Search: "\EA37"
$icon-cart: "\EA39"
$icon-linkedin-main: "\EA40"
$icon-file: "\EA41"
$icon-close2: "\EA42"


%icon
	font-family: "svgfont"
	font-style: normal
	font-weight: normal
	text-rendering: auto
	speak: none
	line-height: 1
	-webkit-font-smoothing: antialiased
	-moz-osx-font-smoothing: grayscale

.icon, [class^="icon-"], [class*=" icon-"]
	@extend %icon
	display: inline-block

.icon-sw-on:before
	content: $icon-sw-on
.icon-out:before
	content: $icon-out
.icon-people:before
	content: $icon-people
.icon-favourite:before
	content: $icon-favourite
.icon-Facebook:before
	content: $icon-Facebook
.icon-linkedin:before
	content: $icon-linkedin
.icon-Instagram:before
	content: $icon-Instagram
.icon-stars:before
	content: $icon-stars
.icon-send:before
	content: $icon-send
.icon-arrow-long:before
	content: $icon-arrow-long
.icon-close-pass:before
	content: $icon-close-pass
.icon-open-pass:before
	content: $icon-open-pass
.icon-arrow-small:before
	content: $icon-arrow-small
.icon-fb:before
	content: $icon-fb
.icon-share:before
	content: $icon-share
.icon-inst:before
	content: $icon-inst
.icon-tw:before
	content: $icon-tw
.icon-download:before
	content: $icon-download
.icon-test-svg:before
	content: $icon-test-svg
.icon-heart:before
	content: $icon-heart
.icon-sw-off:before
	content: $icon-sw-off
.icon-like-active:before
	content: $icon-like-active
.icon-catalog:before
	content: $icon-catalog
.icon-employees:before
	content: $icon-employees
.icon-featured:before
	content: $icon-featured
.icon-my-orders:before
	content: $icon-my-orders
.icon-profile-data:before
	content: $icon-profile-data
.icon-subscribe:before
	content: $icon-subscribe
.icon-krestik:before
	content: $icon-krestik
.icon-reset:before
	content: $icon-reset
.icon-search:before
	content: $icon-search
.icon-troetochie:before
	content: $icon-troetochie
.icon-open:before
	content: $icon-open
.icon-close:before
	content: $icon-close
.icon-Search:before
	content: $icon-Search
.icon-cart:before
	content: $icon-cart
.icon-linkedin-main:before
	content: $icon-linkedin-main
.icon-file:before
	content: $icon-file
.icon-close2:before
	content: $icon-close2
